import { DebounceFunction, positive } from "@/plugins/helpers";
import {
  DiscountData,
  Invoice,
  InvoiceData,
  InvoiceMovementType,
  InvoicePaymentMethodData,
  InvoicePosition,
  InvoicePositionData,
  InvoiceReference,
  InvoiceReferenceData,
} from "@planetadeleste/vue-mc-gw";
import {
  chain,
  concat,
  filter,
  find,
  findIndex,
  forEach,
  get,
  isArray,
  isEmpty,
  isFunction,
  isNil,
  map,
  omit,
  set,
  uniqueId,
} from "lodash";
import { Component, Mixins } from "vue-property-decorator";
import InvoiceMixin from "./InvoiceMixin";
import useMath from "@/composables/math";
import { debug } from "@/composables/debug";
import { Response } from "vue-mc";
import { number } from "mathjs";
import { EventBus } from "@/services/event-bus";
import Queue from "queue-promise";
import { LoadingModule } from "@/store/loading";

const { math } = useMath();

@Component
export default class InvoiceWithReferencesMixin extends Mixins(InvoiceMixin) {
  /**
   * @type {Partial<InvoicePositionData>[]} Position to be added on invoice
   */
  arPositionDataList: Partial<InvoicePositionData>[] = [];
  arInvoiceDataList: Partial<InvoiceData>[] = [];
  arTempReferenceList: InvoiceReference[] = [];

  /**
   * Invoice movement type is DEBIT NOTE
   * @returns {boolean}
   */
  get bIsDN(): boolean {
    return this.iMovementType === InvoiceMovementType.CODE_DEBIT_NOTE;
  }

  get getPriceWithTax(): boolean | undefined {
    return this.priceWithTax;
  }

  loaded() {
    LoadingModule.loaded();
  }

  loading(attach?: string) {
    LoadingModule.loading(attach);
  }

  addReferences(arInvoiceData: InvoiceData[]): void {
    // copy array to reduce and know when is finished
    this.arInvoiceDataList = [...arInvoiceData];

    if (!arInvoiceData.length) {
      this.onEndQueue();
      return;
    }

    const queue = new Queue({
      concurrent: 1,
      interval: 0,
    });

    queue.on("start", () => {
      this.loading();
    });
    queue.on("end", this.onEndQueue);

    forEach(arInvoiceData, async (obData, idx) => {
      queue.enqueue(async () => {
        await this.addReference(
          obData,
          () => {
            // after finish shift item from array
            this.arInvoiceDataList.shift();
            // After all done and positions are collected, add all positions to invoice
            if (
              isEmpty(this.arInvoiceDataList) &&
              this.arPositionDataList.length
            ) {
              // Send a copy and reset local array
              this.addInvoicePositions([...this.arPositionDataList]);
              this.arPositionDataList = [];
            }
          },
          idx
        );
      });
    });
  }

  onEndQueue() {
    // Finally add all references to invoice model
    if (!this.arPositionDataList.length && this.arTempReferenceList.length) {
      const arReferenceList = concat(
        [...this.references],
        this.arTempReferenceList
      );
      this.obInvoice.set("references", arReferenceList);
      this.arTempReferenceList = [];
    }

    // Invoice of type e-Recibo must sync positions with references
    this.syncPositions();
    this.loaded();
  }

  /**
   * Sync invoice positions with non-global references
   */
  syncPositions() {
    if (!this.isReceiptType) {
      return;
    }

    if (isEmpty(this.references)) {
      this.setPositions([]);
      return;
    }

    const arPositionList = filter(this.positions, (obPosition) => {
      return (
        !!find(this.references, { invoice_ref_id: obPosition.item_id }) &&
        obPosition.unit_price !== 0
      );
    });

    this.setPositions(arPositionList);
  }

  /**
   * @description Add reference from obInvoiceData param
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @param {InvoiceData} obInvoiceData
   * @param {Function} fnCallback
   * @param {number} [idx=0]
   * @memberof InvoiceWithReferencesMixin
   */
  async addReference(
    obInvoiceData: InvoiceData,
    fnCallback?: DebounceFunction,
    idx = 0
  ): Promise<void> {
    const obRef = new InvoiceReference({
      invoice_ref_id: obInvoiceData.id,
      sort_order: idx + 1,
    });

    const obRefInvoice = new Invoice({ id: obInvoiceData.id });
    // Load only selected positions
    const arPositionListId: number[] = get(
      obInvoiceData,
      "position_id_list",
      []
    );
    const response = (await obRefInvoice.fetch({
      params: { "filters[no_check_skip_balance]": 1 },
    })) as Response<InvoiceData> | null;

    if (!response || !response.getData()) {
      return;
    }

    const obInvoiceRefData: Partial<InvoiceData> = omit(
      response.getData().data,
      ["discounts"]
    );
    const arDiscounts: DiscountData[] = get(
      response.getData(),
      "data.discounts",
      []
    );

    // Movement type debt use the same ref as item position
    if (
      [
        InvoiceMovementType.CODE_DEBT,
        InvoiceMovementType.CODE_DEBT_RYA,
        InvoiceMovementType.CODE_CANCEL_DEBT,
      ].includes(this.iMovementType)
    ) {
      // Prevent duplicates
      if (this.positions.length) {
        const arInvoiceIdList = map(
          this.positions,
          (obPosition) => obPosition.item_id
        );

        if (
          obInvoiceRefData.id &&
          arInvoiceIdList.includes(obInvoiceRefData.id)
        ) {
          if (isFunction(fnCallback)) {
            fnCallback();
          }
          return;
        }
      }

      // Set invoice price
      const fAmount = get(obInvoiceData, "amount", 0);
      const fPriceValue = fAmount ?? obInvoiceRefData.total_price_value;

      // Set invoice property to InvoiceReference model
      obRef.set("invoice", obInvoiceRefData);
      this.setReference(obRef);

      // Set InvoicePosition
      const obInvoicePositionData: Partial<InvoicePositionData> = {
        item_id: obInvoiceRefData.id,
        price: fPriceValue,
        quantity: 1,
      };
      // this.addInvoicePositions([obInvoicePositionData]);
      this.arPositionDataList.push(obInvoicePositionData);
    } else {
      let arPositions = get(obInvoiceRefData, "positions", []);
      if (!isNil(arPositions) && !isEmpty(arPositions)) {
        // Filter positions by selected on previous table
        // if no selection, pick all positions of referenced invoice
        if (isArray(arPositionListId) && !isEmpty(arPositionListId)) {
          arPositions = filter(arPositions, (obPosition) =>
            arPositionListId.includes(obPosition.id)
          );

          set(obInvoiceRefData, "positions", arPositions);
          set(obInvoiceRefData, "position_id_list", arPositionListId);
        }

        // Set invoice property to InvoiceReference model
        obRef.set("invoice", obInvoiceRefData);

        this.setReference(obRef);
        this.arPositionDataList = concat(this.arPositionDataList, arPositions);
        // this.addInvoicePositions(arPositions);
      }
    }

    // After all done and references are collected, add all references to invoice, add global discounts
    if (!isNil(arDiscounts) && !isEmpty(arDiscounts)) {
      this.addGlobalDiscounts(arDiscounts);
    }

    // Add payment methods
    this.addPaymentMethods(obInvoiceRefData);

    if (isFunction(fnCallback)) {
      fnCallback();
    }
  }

  addPaymentMethods(obInvoiceRefData: Partial<InvoiceData>) {
    const arPaymentMethodList: InvoicePaymentMethodData[] = get(
      obInvoiceRefData,
      "payment_methods",
      []
    );
    const arInvoicePaymentMethodList = [...this.invoicePaymentMethods];

    forEach(arPaymentMethodList, (obPaymentMethod) => {
      const obPredicate: Partial<InvoicePaymentMethodData> = {
        payment_method_id: obPaymentMethod.payment_method_id,
      };
      const obInvoicePM: InvoicePaymentMethodData | undefined = find<
        InvoicePaymentMethodData[]
      >(arInvoicePaymentMethodList, obPredicate) as
        | InvoicePaymentMethodData
        | undefined;

      // Check payment method exists to sum amount instead of duplicate payment methods
      if (obInvoicePM) {
        const iIndexPM = findIndex(arInvoicePaymentMethodList, obPredicate);
        obInvoicePM.amount = math.add(
          obInvoicePM.amount,
          obPaymentMethod.amount
        );
        arInvoicePaymentMethodList.splice(iIndexPM, 1, obInvoicePM);
      } else {
        arInvoicePaymentMethodList.push(obPaymentMethod);
      }
    });

    this.obInvoice.set("payment_methods", arInvoicePaymentMethodList);
  }

  /**
   * @description Push reference to Invoice
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @param {InvoiceReference} obModel
   * @return {void}
   * @memberof InvoiceWithReferencesMixin
   */
  setReference(obModel: InvoiceReference): void {
    // const arReferences: InvoiceReference[] = [...this.references];
    // Check reference if already exists
    if (!obModel.is_global && this.refExists(obModel)) {
      debug(
        `Reference ${obModel.invoice_ref_id} (${obModel.invoice_ref?.order_number}) exists`
      );
      return;
    }

    this.arTempReferenceList.push(obModel);
    // this.obInvoice.set("references", arReferences);
  }

  /**
   * @description Add invoice positions based on referenced invoice
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @param {(InvoicePosition[] | InvoicePositionData[])} arPositionListItem
   * @memberof InvoiceWithReferencesMixin
   */
  addInvoicePositions(
    arPositionListItem: InvoicePosition[] | Partial<InvoicePositionData>[]
  ) {
    // @ts-ignore
    const arPositionList: InvoicePosition[] = chain(arPositionListItem)
      .map(this.mapRefPositions)
      .filter((obPosition) => !isNil(obPosition) && !isEmpty(obPosition))
      .value();
    const arPositions: InvoicePosition[] = concat(
      this.positions,
      arPositionList
    );

    // this.obInvoice.set("positions", arPositions);
    this.setPositions(arPositions);
    // this.addEmptyItemPosition();
  }

  mapRefPositions(
    obPositionItemData: InvoicePosition | Partial<InvoicePositionData>
  ) {
    const obPositionData: Partial<InvoicePositionData> = omit(
      obPositionItemData instanceof InvoicePosition
        ? obPositionItemData.attributes
        : obPositionItemData,
      ["id", "invoice_id"]
    );

    let iPositionId = obPositionItemData.id;

    if (!get(obPositionData, "idx")) {
      set(obPositionData, "idx", uniqueId());
    }

    // Use quantity_balance for quantity property
    if (!this.bIsDN && !this.isReceiptCorrect) {
      // Change original position ID and fill reference ID
      if (!get(obPositionData, "ref_id") && iPositionId) {
        set(obPositionData, "ref_id", iPositionId);
      } else {
        iPositionId = get(obPositionData, "ref_id");
      }

      // Get quantity
      let iQty = number(get(obPositionData, "quantity_balance", 1));

      if (!iQty) {
        iQty =
          obPositionData.quantity && obPositionData.quantity !== 0
            ? obPositionData.quantity
            : 1;
      }

      // Use price_balance for referenced position value and return it always as positive number
      // If price is negative, it use negative quantity
      let fPriceBalance = positive(get(obPositionData, "price_balance", 0));

      if (fPriceBalance && isEmpty(obPositionData.discounts)) {
        if (iQty > 1) {
          fPriceBalance = fPriceBalance / iQty;
        }

        fPriceBalance = this.getUnitPrice(
          fPriceBalance
          // get(obPositionData, "tax_type") as TaxTypeData
        );

        set(obPositionData, "unit_price", fPriceBalance);
        set(obPositionData, "price", fPriceBalance);
        debug("Set price from balance:", fPriceBalance);

        // set(obPositionData, "price_with_discounts", fPriceBalance);
      }

      if (!iQty) {
        return;
      }

      set(obPositionData, "quantity", iQty);
    } else if (iPositionId) {
      // Force change ref_id on Debit Note type
      set(obPositionData, "ref_id", iPositionId);
    }

    if (iPositionId) {
      // Check for already exists position
      const obInvoicePosition = find(this.positions, {
        ref_id: iPositionId,
        // invoice_id: obPositionData.invoice_id,
      });

      if (obInvoicePosition) {
        return;
      }
    }

    debug("Add invoice position", obPositionData);

    const obPosition = new InvoicePosition(obPositionData);
    obPosition.sync();

    return obPosition;
  }

  addGlobalDiscounts(arDiscountData: DiscountData[]) {
    const arInvoiceDiscounts = filter(this.discounts, (obDiscount) => {
      return !isNil(obDiscount.value);
    });
    const arGlobalDiscounts = concat(arInvoiceDiscounts, arDiscountData);
    EventBus.emit("invoice.add.discounts", arGlobalDiscounts);
    EventBus.emit("invoice.apply.discounts");
  }

  /**
   * Calculate unit price based on tax and company settings
   *
   * @date 26/10/2022 - 13:59:13
   * @author Planeta del Este
   *
   * @param {number} fValue
   * @returns {number}
   */
  getUnitPrice(fValue: number): number {
    // if (!this.getPriceWithTax && obTaxType && obTaxType.percent) {
    //   fValue = math.subtract(fValue, reverseTax(fValue, obTaxType.percent));
    //   fValue = round(fValue);
    // }

    return fValue;
  }

  /**
   * @description Check if reference exists on invoice
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @param {(InvoiceReference | InvoiceReferenceData)} obData
   * @return {*}  {boolean}
   * @memberof InvoiceWithReferencesMixin
   */
  refExists(obData: InvoiceReference | InvoiceReferenceData): boolean {
    const obInvoiceRef = find(this.references, {
      invoice_ref_id: obData.invoice_ref_id,
    });

    return !isNil(obInvoiceRef);
  }
}
